import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {}

  click(event) {
    event.preventDefault()
    if (window.confirm('Message will be sent to recipient, continue?')) {
      let form = this.element.closest('form')
      if (form) {
        let perform_field = form.querySelector('#perform')
        if (perform_field) {
          perform_field.value = 1
          form.requestSubmit()
        }
      }
    }
  }

}
