import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['primary', 'secondary']
  static values = { secondaryOptions: Object }

  connect() {}

  populate() {
    this.secondaryTarget.val = ''
    this.secondaryTarget.innerHTML = this.secondaryOptionsValue[this.primaryTarget.value].map(option => `<option value=${option}>${option}</option>`).join('\n')
    if (this.secondaryTarget.tomselect != undefined) {
      this.secondaryTarget.tomselect.clearOptions()
      this.secondaryTarget.tomselect.sync()
    }
  }

}
