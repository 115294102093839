import GLightbox from 'glightbox'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = [  ]
  glightbox = null

  connect() {
    let glightboxController = this
    // init glightbox
    this.glightboxInit()
    // reload items when a slide is added by AJAX
    this.element.arrive('.glightbox', { existing: true }, () => {
      glightboxController.glightbox.reload()
    })
  }

  glightboxInit() {
    this.glightbox = GLightbox({
      selector: '#' + this.element.id + ' ' + this.selector(),
      width: '90vw',
      height: '90vh'
    })
  }

  selector() {
    return this.data.get('selector') || '.glightbox'
  }

}
