import Flatpickr from 'stimulus-flatpickr'

import { Czech } from 'flatpickr/dist/l10n/cs.js'
import { english } from 'flatpickr/dist/l10n/default.js'

export default class extends Flatpickr {

  static values = {
    locale: String,
  }

  locales = {
    cs: Czech,
    en: english
  }

  connect() {
    this.element[this.identifier] = this // store controller into element for master/slave interactions
    this.config = {
      locale: this.locale,
      altInput: true,
      altFormat: this.altFormat,
      time_24hr: true,
      locale: { firstDayOfWeek: 1 },
      allowInput: true
    }
    super.connect()
  }

  get locale() {
    if (this.locales && this.hasLocaleValue) {
      return this.locales[this.localeValue];
    } else {
      return english;
    }
  }

  get altFormat() {
    if (this.data.has('enableTime')) {
      return 'j. F Y H:i'
    } else {
      return 'j. F Y'
    }
  }

  /**
   * Register change on the flatpickr element
   *
   * If the flatpickr has flatkid attached call the parent
   * of flatkid (which is flatkids) and propagate the update
   * information.
   *
   * This is needed for syncing to: and from: flatpickr instances
   */
  change() {
    this.element.flatkid && this.element.flatkid.updated()
  }

}
