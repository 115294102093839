import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.addEventListener('click', (evt) => {
      evt.preventDefault()
      const tf = this.element.closest('turbo-frame')
      if (tf) { tf.innerHTML = '' }
    })
  }

}
