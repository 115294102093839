import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import hotkeys from 'hotkeys-js'
import TomSelect from 'tom-select'
import mustache from 'mustache'

export default class extends Controller {

  static values = { url: String }

  connect() {
    this.ts = new TomSelect(this.element, {
      valueField: 'id',
      options: [],
      create: false,
      maxOptions: 15,
      loadThrottle: 200,
      persist: false,
      searchField: [],
      shouldLoad: (query) => { return query.length > 1 },
      load: (query, callback) => {
        var url = this.urlValue + '?q=' + encodeURIComponent(query)
        fetch(url)
          .then(response => response.json())
          .then(json => {
            this.ts.clearOptions() // https://github.com/orchidjs/tom-select/issues/497
            callback(json)
          })
          .catch(()=>{ callback() })
      },
      render: {
        option: function(item, escape) {
          var template = `
            <div data-detail-url="{{item.detail_url}}" class="hstack">
              <span>{{item.name}}</span>
              {{#item.thumbnail_url}}
              <img src="{{item.thumbnail_url}}" class="img-fluid rounded-1 shadow-sm ms-auto"/>
              {{/item.thumbnail_url}}
            </div>`
          return mustache.render(template, {item: item})
        },
        item: function(item, escape) {
          return `<div data-detail-url="${item.detail_url}">${item.name}`
        }
      },
      onItemAdd: (value, item) => { Turbo.visit(item.dataset.detailUrl) }
    })
    hotkeys('/', (evt) => {
      evt.preventDefault()
      this.ts.focus()
    })
  }

  disconnect() {
    this.ts.destroy()
    hotkeys.unbind('/')
  }

}
